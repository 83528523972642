module.exports = {
  bugsnag: {
    apiKey: 'eaedd46e367aba7f061d1ac349e0ae29',
    appVersion: process.env.APP_VERSION || process.env.RAILS_ENV,
    releaseStage: process.env.RAILS_ENV,
  },
  google: {
    places: {
      apiKey: 'AIzaSyC4LO1kH9F3uinMwNwGMsJulSkOcuyWJ-g',
      version: 'beta',
    }
  }
};
